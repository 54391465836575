import { makeStyles } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    overflow: 'hidden',
    width: 200,
    height: 200,
    top: 0,
    right: 0
  },
  icon: {
    width: 150,
    height: 150,
    fill: theme.palette.primary.main + 'aa',
  },
  wrapper: {
    borderRadius: '50%',
    width: '140%',
    height: '140%',
    lineHeight: '420px',
    textAlign: 'center',
    color: '#aaa',
    border: `5px solid #aaa`,
    
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  text: {
    paddingLeft: 8,
    paddingTop: 4,
    textDecoration: 'none',
    letterSpacing: 1,
    fontSize: '1.3rem',
  }
}))

const LogoRoot = experimentalStyled('svg')();

const BgLogo = (props) => {
  const classes = useStyles();
  
  return (
  <div className={classes.root}>
    <div className={classes.wrapper} style={props.color && { border: `6px solid ${props.color}` }}>
    <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill="#000000">
    <defs>
      <filter id="filter">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.262745 0 0 0 0 0.329412 0 0 0 0 0.866667 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
      <g>
        <rect fill="none" height="24" width="24"/>
      </g>
      <g>
        <g>
          <path d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z" fill={props.color}/>
          <polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93"  fill={props.color}/>
          <polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9"  fill={props.color}/>
        </g>
      </g>
    </svg>
    </div>
    </div>
    )
};

BgLogo.defaultProps = {
    color: '#AAAAAA30'
}

export default BgLogo;
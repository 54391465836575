import React, { FC } from 'react';
import { Avatar, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        textDecoration: 'none',
        color: '#f4f5f7',
        backgroundColor: theme.palette.primary.main,
    },
}))

type AvatarInitialsProps = {
    username: string
}

const AvatarInitials: FC<AvatarInitialsProps> = ({ username }) => {
    const classes = useStyles();

    let initials = 'XY'

    if (username) {
        let initialsArray = [];
        username.split(' ').forEach(name => initialsArray.push(name.charAt(0)))
        initials = initialsArray.join('')
    }


    return (
        <Avatar className={classes.root}>{initials}</Avatar>
    );
}

export default AvatarInitials;





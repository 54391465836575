import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';
import SessionGuard from "./components/SessionGuard";
import { PersistGate } from 'redux-persist/integration/react'
import {persistor} from "./store";

const App = () => {
  const content = useRoutes(routes);

  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return (
      <PersistGate loading={<SplashScreen/>} persistor={persistor}>
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <SnackbarProvider
          dense
          maxSnack={3}
        >
          <GlobalStyles />
          {auth.isInitialized ? content : <SplashScreen />}
        <SessionGuard/>
        </SnackbarProvider>
      </RTL>
    </ThemeProvider>
</PersistGate>
  );
};

export default App;

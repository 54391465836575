import SessionModal from "../pages/viola/pageLayout/SessionModal";
import {setSessionStatus, setSessionValid} from "../store/reducers/user";
import React, {useCallback, useEffect, useState} from "react";
import store, {useDispatch, useSelector} from "../store";
import moment from "moment";
import {useSnackbar} from "notistack";
import {Button, Typography} from "@material-ui/core";
import PlusIcon from "../icons/Plus";
import apiClient from "../api/api";
import useAuth from "../hooks/useAuth";

const SessionGuard = () => {
    const { logout } = useAuth();
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [sessionCounter, setSessionCounter] = useState<number>(0);

    const addNewCookie = () => {
        const currentDate = moment().add(2, 'hours').format('YYYY-MM-DD HH:mm:ss')
        store.dispatch(setSessionValid(currentDate))
    }

    const extendSession = async (id?: string, isAuto?: boolean) => {
        await apiClient.get('api/csrf-cookie')
            .then(() => {
                addNewCookie();
                setSessionCounter(prevState => prevState + 1);
            })
            .then(()=> enqueueSnackbar(`Sesja została ${isAuto ? 'automatycznie' : ''} przedłużona o 2 godziny!`, {variant: 'success'}))
            .finally(()=>  id ? closeSnackbar(id) : null);
    }

    const action = (key: string) => (
        <div>
            <Button
                startIcon={<PlusIcon fontSize="inherit" />}
                variant="contained"
                onClick={()=> extendSession(key)}
                style={{backgroundColor: 'gray'}}
            >
                <Typography
                    fontSize={12}
                >
                    Przedłuż sesję
                </Typography>
            </Button>
        </div>
    );

    const renderExitButton = () => (
        <div>
            <Button
                startIcon={<PlusIcon fontSize="inherit" />}
                variant="contained"
                onClick={handleLogout}
                style={{backgroundColor: 'rgba(27, 40, 145, 0.28)'}}
            >
                <Typography
                    fontSize={12}
                >
                    Zaloguj się
                </Typography>
            </Button>
        </div>
    );

useEffect(
  useCallback(() => {
      if (userData.sessionValid) {
    const timer = setInterval(() => {
      const actualTime = moment()
        const timeDiff = moment.duration(actualTime?.diff(userData.sessionValid));
        const timeLeftToExpireSession = moment.utc(-timeDiff.asMilliseconds()).format('HH:mm');
        const timeLeftToExpireSessionInMinutes = moment(timeLeftToExpireSession, 'HH:mm A').format('mm')
        const timeLeftToExpireSessionInHours = moment(timeLeftToExpireSession, 'HH:mm A').format('HH')
        if (parseInt(timeLeftToExpireSessionInHours) === 0 && parseInt(timeLeftToExpireSessionInMinutes) <= 30 && timeDiff.asMilliseconds() < 0) {
            if (sessionCounter < 2) {
                extendSession(null, true);
            } else {
                enqueueSnackbar(`Do wygaśnięcia sesji pozostało ${timeLeftToExpireSessionInMinutes} min.`, {
                    variant: 'info', autoHideDuration: 40_000,
                    action,
                });
            }
        }
        if (timeDiff.asMilliseconds() > 0) {
            dispatch(setSessionStatus(false))
            enqueueSnackbar(`Sesja wygasła! Zaloguj się ponownie.`, {
                variant: 'error', persist: true,
                action: renderExitButton
            })
            clearInterval(timer);
        }
    }, 60_000);

    return () => {
      clearInterval(timer);
    };}
  }, [userData.sessionValid])
,[userData.sessionValid])

    const handleLogout = async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err)
        } finally {
         closeSnackbar()
        }
    };

  return (
  <>
    {!userData.isSessionActive && userData.isLogin && <SessionModal
        onConfirm={handleLogout}
        question={`Zaloguj się ponownie na stronie głównej`}
        title={'Sesja wygasła'}
        submit={'Powrót'}
    />}
  </>)
};

export default SessionGuard;

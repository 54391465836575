import {
    Avatar,
    Box,
    Button,
    Container,
    Paper,
    Typography,
    makeStyles
} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import React, { FC } from "react";
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles(() => ({
    root: {},
    backdrop: {
        position: 'fixed',
        top: 0,
        left: '18%',
        bottom: 0,
        right: 0,
        backgroundColor: 'black',
        zIndex: 999,
        display: 'grid',
        placeItems: 'center',
    }
}))

type SessionModalProps = {
    onConfirm: () => void,
    question: string,
    title: string,
    submit: string,
    add?: boolean
}

const SessionModal: FC<SessionModalProps> = ({ onConfirm, question, title, submit, add }) => {
    const classes = useStyles();

    return (
        <Box className={classes.backdrop}>
            <Container maxWidth="sm">
                <Paper elevation={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            pb: 2,
                            pt: 3,
                            px: 3
                        }}
                    >
                        {add ?
                            <Avatar
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
                                    color: 'success.main',
                                    mr: 2
                                }}
                            >
                                <AddCircleOutlinedIcon />
                            </Avatar>
                            :
                            <Avatar
                                sx={{
                                    backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                                    color: 'error.main',
                                    mr: 2
                                }}
                            >
                                <WarningIcon />
                            </Avatar>}
                        <Box>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {title}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                sx={{ mt: 1 }}
                                variant="body2"
                            >
                                {question}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            px: 3,
                            py: 1.5
                        }}
                    >
                        <Button
                            sx={{
                                backgroundColor: 'primary',
                                '&:hover': {
                                    backgroundColor: 'secondary'
                                }
                            }}
                            onClick={onConfirm}
                            variant="contained"
                            endIcon={<HomeIcon fontSize="small" />}
                        >
                            {submit}
                        </Button>
                    </Box>
                </Paper>
            </Container>
        </Box>
    )
};

export default SessionModal;

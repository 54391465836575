import { Box } from '@material-ui/core';
import { CSSProperties } from 'react';
import React from 'react';
import { BounceLoader } from "react-spinners";

type LoadingScreenTypes = {
    backgroundColor?: string,
    style?: CSSProperties,
}

const LoadingScreen: React.FC<LoadingScreenTypes> = ({ backgroundColor, style }) => {

    return (
        <Box
            sx={{
                backgroundColor: backgroundColor || 'background.paper',
                minHeight: '100%',
                display: 'grid',
                placeItems: 'center',
            }}
            style={style}
        >
            <BounceLoader color={'#5664d2'} loading={true} size={150} />

        </Box>
    );
};

export default LoadingScreen;

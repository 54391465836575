import { makeStyles } from '@material-ui/core';
import {LogoIcon} from "../../assets/images";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textDecoration: 'none',
  },
  icon: {
    width: 20,
    height: 20,
    fill: theme.palette.primary.main + 'aa',
  },
  wrapper: {
    borderRadius: '50%',
    width: 35,
    height: 35,
    lineHeight: '40px',
    textAlign: 'center',
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}90`
  },
  text: {
    paddingLeft: 8,
    paddingTop: 4,
    textDecoration: 'none',
    letterSpacing: 1,
    fontSize: '1.3rem',
  }
}))

const Logo = () => {
  const classes = useStyles();

  return (
  <div className={classes.root}>
      <LogoIcon  style={styles.logoStyle}/>
    </div>
)};

const styles = {  logoStyle: {
    height: 40,
    width: 100,
  },}

export default Logo;

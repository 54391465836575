import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import apiClient from 'src/api/api';
import store, { useSelector } from '../store';
import { setLoginStatus, setSessionStatus, setSessionValid } from '../store/reducers/user';
import moment from 'moment';
import axios from 'axios';

const apiPermissions = [
    'view cash reports', 'edit cash reports', 'admin', 'view complaints', 'edit complaints',
    'view product transfer', 'edit product transfer', 'view firm partners', 'edit firm partners',
    'view evidence controls', 'edit evidence controls', 'view employees', 'edit employees',
    'view departments', 'edit departments', 'view approval payments', 'edit approval payments',
    'view payment terminal', 'edit payment terminal', 'view shops management', 'edit shops management',
    'view orders', 'edit orders', 'view partner orders', 'edit partner orders', 'view drivers', 'edit drivers',
    'view kiosks', 'edit kiosks', 'view store application', 'edit store application', 'view sub cash reports',
    'edit sub cash reports', 'view warehouse', 'view distributing product', 'edit distributing product',
    'edit warehouse', 'view external shipment', 'edit external shipment', 'view inventory', 'edit inventory',
    'view products', 'edit products', 'view products categories', 'edit products categories',
    'view products shipment', 'edit products shipment', 'view printers', 'edit printers', 'view sectors',
    'edit sectors', 'view sorter', 'edit sorter', 'view sorter products', 'edit sorter products',
    'view product finder', 'edit product finder', 'view shops monitoring', 'edit shops monitoring',
    'view selling report', 'edit selling report', 'view warehouse report', 'edit warehouse report',
    'view viola pl', 'edit viola pl', 'view product reservations', 'view warehouse orders', 'edit warehouse orders'
];

type Role = 'admin' | 'user';
type Permission = typeof apiPermissions[number];

interface User {
    id: number;
    role: Role[];
    permission: Permission[];
}

interface UserResponseType {
    id: number;
    role: Role[];
    permission: Permission[];
}

interface State {
    isAuthenticated: boolean;
    isInitialized: boolean;
    user: User | null;
    permissions: Record<string, boolean>;
}

interface Action {
    type: 'INITIALISE' | 'LOGIN' | 'REGISTER' | 'LOGOUT';
    payload: {
        isAuthenticated?: boolean;
        user?: User;
    };
}

const hasPermission = (user: User | null, permission: Permission): boolean => {
    if (!user) return false;
    if (user.role.includes('admin')) return true;
    return user.permission.includes(permission);
};

const initializeUser = (user: User | null): User | null => {
    if (!user) return null;
    if (user.role.includes('admin')) {
        return {
            ...user,
            permission: apiPermissions,
        };
    }
    return user;
};

const createPermissions = (user: User | null): Record<string, boolean> => {
    const permissions: Record<string, boolean> = {};

    if (user) {
        apiPermissions.forEach(permission => {
            const camelCaseKey = permission.split(' ').map((word, index) => {
                return index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }).join('');

            permissions[camelCaseKey] = hasPermission(user, permission);
        });
    } else {
        apiPermissions.forEach(permission => {
            const camelCaseKey = permission.split(' ').map((word, index) => {
                return index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }).join('');

            permissions[camelCaseKey] = false;
        });
    }
    return permissions;
};


const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'INITIALISE':
        case 'LOGIN':
        case 'REGISTER': {
            const { isAuthenticated, user } = action.payload;
            const updatedUser = initializeUser(user || null);
            return {
                ...state,
                isAuthenticated: isAuthenticated || false,
                isInitialized: true,
                user: updatedUser,
                permissions: createPermissions(updatedUser),
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                permissions: createPermissions(null),
            };
        }
        default:
            return state;
    }
};

const initialAuthState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    permissions: createPermissions(null),
};

const AuthContext = createContext({
    ...initialAuthState,
    platform: 'COOKIE',
    login: async (email: string, password: string) => {},
    newPassword: async (queryParams: string, password: string, submitPassword: string) => {},
    logout: async () => {},
    register: async (email: string, name: string, password: string) => {},
    checkUser: async () => {},
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const userData = useSelector((state: any) => state.user);

    const checkUser = async () => {
        try {
            const response = await apiClient.get('api/me');
            const user = response.data;
            dispatch({
                type: 'INITIALISE',
                payload: {
                    user,
                    isAuthenticated: Boolean(user),
                },
            });
        } catch (error) {
            dispatch({
                type: 'INITIALISE',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });
        }
    };

    const newPassword = async (queryParams: string, password: string, submitPassword: string) => {
        try {
            await apiClient.post(`api/welcome/${queryParams}`, {
                password,
                password_confirmation: submitPassword,
            });
        } catch (err) {
            console.error(err);
        }
    };

    const addNewCookie = () => {
        const currentDate = moment().add(118, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        store.dispatch(setSessionValid(currentDate));
    };

    const login = async (email: string, password: string) => {
        try {
            await apiClient.get('api/csrf-cookie');
            addNewCookie();
            await apiClient.post('api/login', { email, password });
            const response = await apiClient.get('api/me');
            const user = response.data;
            if (user?.email_verified_at !== null) {
                dispatch({
                    type: 'LOGIN',
                    payload: { user },
                });
                store.dispatch(setSessionStatus(true));
                store.dispatch(setLoginStatus(true));
            } else {
                console.log('Email not verified');
            }
        } catch (error) {
            store.dispatch(setSessionStatus(false));
            store.dispatch(setLoginStatus(false));
            console.error(error);
        }
    };

    const logout = async () => {
        dispatch({ type: 'LOGOUT', payload: {} });
        store.dispatch(setLoginStatus(false));
        store.dispatch(setSessionStatus(false));
        store.dispatch(setSessionValid(''));
        try {
            await apiClient.post('api/logout');
        } catch (error) {
            console.error(error);
        }
    };

    const register = async (email: string, name: string, password: string) => {
        try {
            const response = await axios.post('/api/account/register', { email, name, password });
            const { accessToken, user } = response.data;
            window.localStorage.setItem('accessToken', accessToken);
            dispatch({
                type: 'REGISTER',
                payload: { user },
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const initialise = async () => {
            if (userData.isSessionActive) {
                try {
                    const response = await apiClient.get('/api/me');
                    const user: UserResponseType = response.data;
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    });
                } catch {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } else {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };
        initialise().catch();
    }, [userData]);

    if (!state.isInitialized) {
        return <SplashScreen />;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'COOKIE',
                login,
                logout,
                register,
                checkUser,
                newPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;

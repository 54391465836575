import * as Yup from 'yup';
import { Formik } from 'formik';
import { Alert, Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import {useEffect, useState} from "react";
import apiClient from "../../../api/api";
import {useSnackbar} from "notistack";

const LoginJWT = (props) => {
  const isMountedRef = useIsMountedRef();
  const { login } = useAuth();
    const [resetPassword, setResetPassword] = useState(false)
    const [wrongMail, setWrongMail] = useState(false)
    const { enqueueSnackbar } = useSnackbar();

    const handleResetPassword = async (email) => {
        setResetPassword(true)
        try {
            const response = await apiClient.post(`api/password/email?email=${email}`);
            if (response) {
                enqueueSnackbar(`${response.data.message}`, { variant: 'success'})
                setWrongMail(false)
            } else enqueueSnackbar(`Brak danych użytkownika`, { variant: 'error'})
        } catch (err) {
            enqueueSnackbar(`${err}`, { variant: 'error'})
            setWrongMail(true)
        }
    };


  return (
    <Formik
      initialValues={{
        // email: 'hkoelpin@example.org',
        // password: 'password',
          email: '',
          password: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          email: Yup
            .string()
            .email('Nieodpowiedni format adresu e-mail')
            .max(255)
            .required('Email jest wymagany'),
          password: Yup
            .string()
            .max(255)
            .required('Hasło jest wymagane')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setResetPassword(false);
        try {
            const response = await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Adres E-mail"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            style={{ backgroundColor: '#fff' }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Hasło"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Zaloguj Się
            </Button>
          </Box>
            <Box sx={{ mt: 2 }}>
            <Button
                type="button"
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                variant="outlined"
                onClick={()=> {handleResetPassword(values.email)}}
            >
                Przypomnij hasło
            </Button>
            </Box>
          <Box sx={{ mt: 2 }}>
            {errors.submit && !resetPassword && (<Alert severity="error">
              <div>
                Nie udało się zalogować do sieci Viola
              </div>
            </Alert>)}
              {errors.email && resetPassword && (<Alert severity="error">
                  <div>
                      Wpisz email do zresetowania hasła
                  </div>
              </Alert>)}
              {!errors.email && resetPassword && !wrongMail && (<Alert severity="success">
                  <div>
                      Na wskazany email wysłano link do resetowania hasła
                  </div>
              </Alert>)}
              {!errors.email && resetPassword && wrongMail && (<Alert severity="error">
                  <div>
                      Nie odnaleziono adresu e-mail
                  </div>
              </Alert>)}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;

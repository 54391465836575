import axios from 'axios';
import {setLoginStatus, setSessionStatus} from "../store/reducers/user";
import store from "../store";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || process.env.REACT_APP_BASE_URL,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }
});

export const baseURL = process.env.REACT_APP_API_BASE_URL || process.env.REACT_APP_BASE_URL

export const apiClientWithoutCredentials = axios.create({
    withCredentials: true,
    headers: {
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'OPTIONS,HEAD,GET',
    }
});

apiClient.interceptors.response.use(
    (res) => res,
    (err) => {
        if (err.response.status === 401) {
            store.dispatch(setSessionStatus(false))
            store.dispatch(setLoginStatus(false))
        }
        throw err;
    }
);

export default apiClient;
